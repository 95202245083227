.kvkk {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.kvkk > p {
    font-size: 16px;
}