.privacy {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.privacy > p {
    font-size: 16px;
}