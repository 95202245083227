.AppWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    background: rgb(245,130,40);
    background: linear-gradient(0deg, rgba(245,130,40,1) 0%, rgba(245,135,41,1) 25%, rgba(246,165,57,1) 65%, rgba(246,175,57,1) 100%);
}

.AppLink, 
.AppLink:hover, 
.AppLink:visited, 
.AppLink:link, 
.AppLink:active{
    text-decoration: none;
    color: #000000;
}

.AppLogo {
    cursor: pointer;
}

.AppDescription {
    margin-top: 15px;
}

.AppStore {
    margin-top: 10px;
}

.legal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.legalLink {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
}

.description {
    margin: 20px;
    margin-top: 50px;
}